import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Select from '../../../components/Select';
import LoadingSpinner from '../../../components/LoadingSpinner';
import s from './OpenPosition.module.scss';
import RightArrow from './ic-arrow-next.svg';

const propTypes = {
  openPositions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isPositionsLoading: PropTypes.bool.isRequired,
};

const OpenPosition = ({ openPositions, isPositionsLoading }) => {
  const [departmentWisePositions, setDepartmentWisePositions] = useState({});
  const [selectedOffice, setSelectedOffice] = useState('All Offices');
  const [officesList, setOfficesList] = useState([{ name: 'All Offices', value: 'All Offices' }]);
  const [selectedDepartment, setSelectedDepartment] = useState('All Department');
  const [departmentsList, setDepartmentsList] = useState([
    { name: 'All Department', value: 'All Department' },
  ]);

  const onDepartmentSelected = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const onOfficeSelected = (e) => {
    setSelectedOffice(e.target.value);
  };

  // group positions by department.
  useEffect(() => {
    const tempDepartmentWisePositions = {};
    const tempOfficesSet = new Set();

    // store all position department wise.
    openPositions.forEach((position) => {
      tempOfficesSet.add(position.offices[0].name);
      position.departments.map((department) => {
        if (tempDepartmentWisePositions[department.name]) {
          tempDepartmentWisePositions[department.name].push(position);
        } else {
          tempDepartmentWisePositions[department.name] = [position];
        }
        return null;
      });
    });
    setDepartmentWisePositions({ ...tempDepartmentWisePositions });

    // store all departments.
    setDepartmentsList([
      { name: 'All Department', value: 'All Department' },
      ...Object.keys(tempDepartmentWisePositions).map((department) => ({
        name: department,
        value: department,
      })),
    ]);

    // store all offices.
    setOfficesList([
      { name: 'All Offices', value: 'All Offices' },
      ...Array.from(tempOfficesSet).map((value) => ({ name: value, value })),
    ]);
  }, [openPositions]);

  // filter positions by department or office.
  useEffect(() => {
    const tempDepartmentWisePositions = {};

    // store all position department wise.
    openPositions.forEach((position) => {
      if (selectedOffice === 'All Offices' || position.offices[0].name === selectedOffice) {
        position.departments.map((department) => {
          if (selectedDepartment !== 'All Department' && department.name !== selectedDepartment) {
            return null;
          }
          if (tempDepartmentWisePositions[department.name]) {
            tempDepartmentWisePositions[department.name].push(position);
          } else {
            tempDepartmentWisePositions[department.name] = [position];
          }
          return null;
        });
      }
    });
    setDepartmentWisePositions({ ...tempDepartmentWisePositions });
  }, [selectedDepartment, selectedOffice, openPositions]);

  return (
    <div id="openPositions" className={s.container} data-test="openPositionsContainer">
      <h2 data-aos="fade-up" className={s.title}>
        Open Positions
      </h2>
      <div data-aos="fade-up" className={s.filters}>
        <Select
          data-test="filters"
          value={selectedDepartment}
          label="Department"
          items={departmentsList}
          onChange={onDepartmentSelected}
        />
        <Select
          data-test="filters"
          value={selectedOffice}
          label="Offices"
          items={officesList}
          onChange={onOfficeSelected}
        />
      </div>
      <div className={s.departmentContainer}>
        {/* Show Loading Spinner while API is processing */}
        {isPositionsLoading && (
          <div className={s.loadingSpinner} data-test="loadingSpinnerContainer">
            <LoadingSpinner />
          </div>
        )}

        {/* Render List of Open Positions */}
        {!isPositionsLoading &&
          Object.keys(departmentWisePositions).map((department) => (
            <div className={s.departmentContent} key={department} data-test="departments">
              <h5 data-aos="fade-up" className={s.departmentTitle} data-test="departmentTitle">
                {department}
              </h5>
              {departmentWisePositions[department].map((position) => {
                return (
                  <Link key={position.id} to={`/careers/${position.id}`} data-test="positions">
                    <div data-aos="fade-up" className={s.positionContainer}>
                      <div className={s.positionContent}>
                        <h3 data-test="positionTitle">{position.title}</h3>
                        <div className={s.positionTail}>
                          <p>{`${position?.metadata[0]?.value}  •  ${position.offices[0].name}`}</p>
                        </div>
                      </div>
                      <div className={s.arrow}>
                        <RightArrow />
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          ))}

        {/* Render if No Open Jobs available  */}
        {!isPositionsLoading && Object.keys(departmentWisePositions).length === 0 && (
          <h3 data-aos="fade-up" className={s.NoJobsMessage} data-test="NoJobsMessage">
            No Jobs Found
          </h3>
        )}
      </div>
    </div>
  );
};

OpenPosition.propTypes = propTypes;
export default OpenPosition;
