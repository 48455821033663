import React from 'react';
import PropTypes from 'prop-types';
import s from './Select.module.scss';
import ArrowDown from './ArrowDown.svg';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isError: PropTypes.bool,
  helperText: PropTypes.string,
};

const defaultProps = {
  items: [],
  label: '',
  onChange: () => {},
  value: '',
  placeholder: '',
  isError: false,
  helperText: '',
};

const Select = ({ label, value, placeholder, items, onChange, isError, helperText }) => (
  <div className={s.container}>
    <p>{label}</p>
    <div className={s.selectContainer}>
      <select
        placeholder={placeholder}
        onChange={onChange}
        name={label}
        value={!value ? placeholder : value}
      >
        {placeholder && (
          <option disabled hidden>
            {placeholder}
          </option>
        )}
        {items.map((item) => (
          <option key={item.value} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
      <figure className={s.iconContainer}>
        <ArrowDown />
      </figure>
    </div>
    {isError && <p className={s.helperText}>{helperText}</p>}
  </div>
);

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
