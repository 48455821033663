import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import classNames from 'classnames';

import Button from '../Button';
import s from './ListView.module.scss';

const propTypes = {
  icon: PropTypes.shape({
    fluid: PropTypes.shape({}),
  }).isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  linkTitle: PropTypes.string,
  link: PropTypes.string,
  isZigZagList: PropTypes.bool,
};

const defaultProps = {
  linkTitle: '',
  link: '',
  isZigZagList: false,
};

const ListView = ({ icon, title, desc, link, linkTitle, isZigZagList }) => (
  <div className={classNames(s.root, isZigZagList && s.serviceList)}>
    <div className={s.container} data-aos="fade-up" data-aos-delay="400">
      <Img fluid={icon.fluid} className={s.emoji} />
      <div className={s.detail}>
        <h3 className={s.title}>{title}</h3>
        <p className={s.desc}>{desc}</p>
        {linkTitle && (
          <Link className={s.link} to={link}>
            <Button>{linkTitle}</Button>
          </Link>
        )}
      </div>
    </div>
  </div>
);

ListView.defaultProps = defaultProps;
ListView.propTypes = propTypes;
export default ListView;
