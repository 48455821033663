import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Careers from '../../containers/Careers';

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      footerDescription: PropTypes.shape({}).isRequired,
      icons: PropTypes.arrayOf(
        PropTypes.shape({
          fluid: PropTypes.shape({}),
        }),
      ).isRequired,
    }).isRequired,
    contentfulCareersPage: PropTypes.shape({
      benefits: PropTypes.array.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      detailDescription: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const CareersPage = ({ data }) => {
  const { GATSBY_GREENHOUSE_BASE_URL } = process.env;
  const [openPositions, setOpenPositions] = useState([]);
  const [isPositionsLoading, setIsPositionsLoading] = useState(false);

  // Fetch open positions from greenhouse.io
  useEffect(() => {
    setIsPositionsLoading(true);
    fetch(`${GATSBY_GREENHOUSE_BASE_URL}jobs?content=true`)
      .then((res) => res.json())
      .then((res) => {
        if (res?.jobs) {
          setOpenPositions(res.jobs);
        } else {
          setOpenPositions([]);
        }
        setIsPositionsLoading(false);
      })
      .catch(() => {
        setOpenPositions([]);
        setIsPositionsLoading(false);
      });
  }, [GATSBY_GREENHOUSE_BASE_URL]);
  return (
    <Careers
      companyQuote={data.contentfulCompanyQuote}
      benefits={data.contentfulCareersPage.benefits}
      title={data.contentfulCareersPage.title}
      description={data.contentfulCareersPage.description}
      detailDescription={data.contentfulCareersPage.detailDescription}
      openPositions={openPositions}
      isPositionsLoading={isPositionsLoading}
    />
  );
};

CareersPage.propTypes = propTypes;
export default CareersPage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      footerDescription {
        json
      }
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    contentfulCareersPage {
      title
      description
      detailDescription
      benefits {
        title
        description
        icon {
          fluid {
            src
            srcSet
          }
        }
      }
    }
  }
`;
