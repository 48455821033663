/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import RightArrow from './arrow-right.svg';
import LeftArrow from './arrow-left.svg';
import '../../../styles/slick.scss';
import '../../../styles/slick-theme.scss';
import s from './WorkingWithUs.module.scss';

const propTypes = {
  description: PropTypes.string.isRequired,
};

class WorkingWithUs extends Component {
  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.state = { current: 1 };
  }

  handleNext() {
    this.slider.slickNext();
  }

  handlePrevious() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      className: 'slider variable-width',
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      arrows: false,
      afterChange: (currentIndex) => this.setState({ current: currentIndex + 1 }),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 300,
          settings: 'unslick', // destroys slick
        },
      ],
    };
    const { current } = this.state;
    const { description } = this.props;
    return (
      <div className={s.root}>
        <div className={s.container}>
          <h3 className={s.head} data-aos="fade-up" data-aos-delay="400">
            {description}
          </h3>
          <div data-aos="fade-up" data-aos-delay="600">
            <StaticQuery
              query={graphql`
                query {
                  allFile(
                    filter: {
                      extension: { regex: "/png/" }
                      relativeDirectory: { eq: "office" }
                      childImageSharp: { fluid: {} }
                    }
                    sort: { fields: name }
                  ) {
                    edges {
                      node {
                        childImageSharp {
                          fixed(height: 500) {
                            ...GatsbyImageSharpFixed_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              `}
              render={(data) => (
                <>
                  <div className={s.slider}>
                    <Slider ref={(c) => (this.slider = c)} {...settings}>
                      {data.allFile.edges.map((singleNode) => (
                        <div
                          key={singleNode.node.childImageSharp.fixed.src}
                          className={s.imageContainer}
                        >
                          <Img className={s.img} fixed={singleNode.node.childImageSharp.fixed} />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className={s.nav}>
                    <button
                      className={s.navBtn}
                      type="button"
                      onClick={this.handlePrevious}
                      aria-label="Slide image carousel left"
                    >
                      <LeftArrow />
                    </button>
                    <p className={s.indicator}>{`${current}/${data.allFile.edges.length}`}</p>
                    <button
                      className={s.navBtn}
                      type="button"
                      onClick={this.handleNext}
                      aria-label="Slide image carousel right"
                    >
                      <RightArrow />
                    </button>
                  </div>
                </>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

WorkingWithUs.propTypes = propTypes;
export default WorkingWithUs;
