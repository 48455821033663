import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import CompanyQuote from '../../components/CompanyQuote';
import { getSeoConfig } from '../../constants/defaultSeoProps';
import OpenPosition from './OpenPosition';
import BenefitsAndPerks from './BenefitsAndPerks';
import WorkingWithUs from './WorkingWithUs';

const propTypes = {
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    footerDescription: PropTypes.shape({}).isRequired,
    icons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  benefits: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  detailDescription: PropTypes.string.isRequired,
  openPositions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isPositionsLoading: PropTypes.bool.isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/careers.png',
  ogTitle: 'Jobs | Tech Holding',
  ogDesc:
    "Join TechHolding's dynamic team and unlock exciting career opportunities. Explore our diverse roles and become part of a forward-thinking community shaping the future of technology.",
  ogPath: '/careers',
};

const updatedSeo = getSeoConfig(seoParams);

const Careers = ({
  companyQuote,
  benefits,
  title,
  description,
  detailDescription,
  openPositions,
  isPositionsLoading,
}) => {
  const handleScrollToOpenPositions = () => {
    const element = document.getElementById('openPositions');
    const scrollOffset = element.getBoundingClientRect().top - 50;
    window.scrollBy({ top: scrollOffset, behavior: 'smooth' });
  };
  return (
    <Layout
      currentPage="/careers"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      <PageHeader
        title={title}
        description={description}
        buttonDetails={{
          label: 'See Open Positions',
          onClick: handleScrollToOpenPositions,
        }}
      />
      <WorkingWithUs description={detailDescription} />
      <BenefitsAndPerks benefits={benefits} />
      <OpenPosition openPositions={openPositions} isPositionsLoading={isPositionsLoading} />
      <CompanyQuote companyQuote={companyQuote} />
    </Layout>
  );
};

Careers.propTypes = propTypes;
export default Careers;
