import React from 'react';
import PropTypes from 'prop-types';
import ListView from '../../../components/ListView';
import s from './BenefitsAndPerks.module.scss';

const propTypes = {
  benefits: PropTypes.array.isRequired,
};

const BenefitsAndPerks = ({ benefits }) => (
  <div className={s.root}>
    <div className={s.container}>
      <h2 className={s.title}>Benefits & Perks</h2>
      <div className={s.BenefitAndPerk}>
        {benefits.map((service) => (
          <ListView
            icon={service.icon}
            title={service.title}
            desc={service.description}
            key={service.id}
          />
        ))}
      </div>
    </div>
  </div>
);

BenefitsAndPerks.propTypes = propTypes;
export default BenefitsAndPerks;
